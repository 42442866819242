$font-family-sans-serif:"Barlow", sans-serif;
$body-bg:grey;
$white: #ffffff;
$bg-light:#dadada;
$bg-dark: #445056;
$body-color:#306D2D;
$grid-gutter-width: 30px;
$primary: #047ce6;
$secondary: #263238;
$success: #00f260;
$alert: #ff9e00;
$awaiting: #a3a7aa;
$theme-colors: (
  "primary": #306d2d,
  "secondary": #ffffff,
  "success": #00f260
);


// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import '~material-design-icons/iconfont/material-icons.css';

// Bootstrap Overrides
// @import "fonts";
@import "mixins";
@import "navigation";
@import "content";
@import "icons";
